<template>
  <v-dialog
    v-model="dialog"
    max-width="400"
  >
    <template #activator="{ on }">
      <v-btn
        color="error"
        icon
        outlined
        title="Flag"
        class="mr-2"
        v-on="on"
      >
        <i class="fas fa-flag-alt" />
      </v-btn>
    </template>
    <v-form
      ref="flagForm"
      v-model="valid"
      @submit.prevent="flagForReview(goBack)"
    >
      <v-card>
        <v-card-title>
          <v-icon
            small
            class="mr-2"
          >
            fas fa-flag-alt
          </v-icon>
          Flag
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-select
            v-model="status"
            :items="statuses"
            label="Status"
            :rules="rules.required"
            class="mt-3"
            outlined
          >
            <template #selection="{ item }">
              <status-chip
                :key="item"
                :status="item"
                block
              />
            </template>
          </v-select>
          <v-textarea
            v-model="reason"
            label="Details"
            :rules="rules.required"
            outlined
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            :disabled="loading"
            color="default"
            text
            @click="dialog = false"
          >
            {{ $t('Cancel') }}
          </v-btn>
          <v-btn
            :loading="loading"
            type="submit"
            color="primary"
          >
            {{ $t('Submit') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
  export default {
    components: {
      StatusChip: () => import('@/components/StatusChip.vue'),
    },
    props: {
      uuid: {
        type: String,
        default: null,
      },
      load: {
        type: [String,Boolean],
        default: null,
      },
      title: {
        type: String,
        default: 'Flag for Review',
      },
      type: {
        type: String,
        default: 'lab_results',
      },
      goBack: Boolean,
    },
    data: () => ({
      model: {
        'lab_results': {
          "name": "Lab Results",
          "successMsg": "Lab result has been flagged for review",
        },
        'reports': {
          "name": "Reports",
          "successMsg": "Report has been flagged for review",
        },
      },
      valid: false,
      dialog: false,
      loading: false,
      status: 'Contact Lab',
      reason: null,
      statuses: [
        'Contact Lab',
        'Tech Support',
        'Do Not Interpret',
        'Needs Review',
      ],
      rules: {
        required: [
          v => !!v || 'This field is required',
        ],
      },
    }),
    methods: {
      flagForReview (goBack) {
        this.$refs.flagForm.validate()
        if (this.valid) {
          this.loading = true
          this.axios.post(`${this.type}/${this.uuid}/flag`, {
            status: this.status,
            reason: this.reason,
            load: this.load,
          })
            .then((res) => {
              if (!goBack && this.load && res.data.next) {
                this.$emit('flagged', res.data.next)
              } else if (goBack || (this.load && !res.data.next)) {
                this.$router.push({ name: this.model[this.type]['name'] })
              }
              this.dialog = false
              this.$toast.success(this.model[this.type]['successMsg'])
            })
            .catch(error => {
              if (error.response?.data?.message) {
                this.$toast.error(error.response.data.message)
              }
            })
            .finally(() => { this.loading = false })
        }
      },
    },
  }
</script>
